import React, { Component } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import SEO from "../components/seo"
import BlogPostCard from "../components/BlogPostCard"
import Navbar from "../components/Navbar"

const PageContainer = styled.div`
  display: grid;

  grid-template-areas:
    "header header header"
    "nav content side"
    "footer footer footer";

  grid-template-columns: 300px 1fr;
  grid-template-rows: auto 1fr auto;
  grid-gap: 10px;

  height: 100vh;
  @media (max-width: 768px) {
    grid-template-areas:
      "header"
      "nav"
      "content"
      "side"
      "footer";

    grid-template-columns: 1fr;
    grid-template-rows:
      auto /* Header */
      minmax(75px, auto) /* Nav */
      1fr /* Content */
      minmax(75px, auto) /* Sidebar */
      auto; /* Footer */
  }
`

const Header = styled.div`
  grid-area: header;
`

const Tag = styled.button`
  font-size: 0.8rem;
  height: 2.8rem;
  line-height: 2.8rem;
  padding: 0 1.5rem;
  border-radius: 20px;
  margin: 0.5rem;
`

const InertTag = styled.button`
  font-size: 0.8rem;
  height: 2.8rem;
  line-height: 2.8rem;
  padding: 0 1.5rem;
  border-radius: 20px;
  margin: 0.5rem;
  pointer-events: none;
`

const Sidebar = styled.div`
  grid-area: nav;
  width: 300px;
  opacity: 95%;
  background-color: #f1f1f1;
  overflow-y: auto;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.2);
  z-index: 5;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-area: nav;
  transition: all 0.3s;
  @media (max-width: 768px) {
    margin: 0;
    padding: 0;
    bottom: 0;
    position: fixed;
    height: 100%;
    ${props =>
      props.open
        ? `transform: translateX(0);`
        : `transform: translateX(-200%);`}
  }
`

const TagContainerDiv = styled.div`
  overflow: auto;
`

const CloseButton = styled.button`
  font-size: 3rem;
  border-radius: 0px;
  position: absolute;
  z-index: 2;
  right: 0;
  display: grid;
  padding: 0px 5px 5px 5px;
  place-content: center;
  @media (min-width: 768px) {
    display: none;
  }
`

const TagPanel = styled.div`
  display: grid;
  justify-content: center;
  @media (min-width: 768px) {
    display: none;
  }
`

const PostPanel = styled.div`
  grid-area: content;
`

const FilterContainer = styled.div`
  h2 {
    margin-bottom: 0px;
  }
  margin-bottom: 10px;
`

class TagsPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sidebarOpen: false,
      selected:
        props.location &&
        props.location.state &&
        props.location.state.initialSelected
          ? [props.location.state.initialSelected]
          : [],
    }
  }

  handleTagClick = e => {
    const { value: tagClicked } = e.target
    if (this.state.selected.includes(tagClicked)) {
      this.setState({
        selected: this.state.selected.filter(tag => tag !== tagClicked),
      })
      return
    }
    this.setState({
      selected: [...this.state.selected, e.target.value],
    })
  }

  closeSidebar = e => {
    this.setState({
      sidebarOpen: false,
    })
  }

  openSidebar = e => {
    this.setState({
      sidebarOpen: true,
    })
  }

  render() {
    return (
      <PageContainer>
        <SEO title="Tags" />
        <Header>
          <Navbar />
        </Header>
        <Sidebar open={this.state.sidebarOpen}>
          <header>
            <CloseButton onClick={this.closeSidebar}>&times;</CloseButton>
          </header>
          <TagContainerDiv>
            <h1>Filters</h1>
            <div>
              {this.props.data.allMarkdownRemark.edges
                .filter(
                  post =>
                    post.node.fields.sourceInstanceName === "markdown-taggroups"
                )
                .map(edge => (
                  <FilterContainer>
                    <h2>{edge.node.frontmatter.name}</h2>
                    {edge.node.frontmatter.includedtags.map(tag => (
                      <Tag
                        key={tag}
                        className={
                          this.state.selected.includes(tag) || "button-outline"
                        }
                        onClick={this.handleTagClick}
                        value={tag}
                      >
                        {tag}
                      </Tag>
                    ))}
                  </FilterContainer>
                ))}
            </div>
          </TagContainerDiv>
        </Sidebar>
        <PostPanel>
          <TagPanel>
            <h1>Search Tags</h1>
            <button styles={{ width: "100%" }} onClick={this.openSidebar}>
              Open Filters
            </button>
            <div>
              {this.state.selected.map(tag => (
                <InertTag key={tag} className={"button-outline"} value={tag}>
                  {tag}
                </InertTag>
              ))}
            </div>
          </TagPanel>
          <div>
            {this.props.data.allMarkdownRemark.edges
              .filter(
                post => post.node.fields.sourceInstanceName === "markdown-pages"
              )
              .filter(post =>
                this.state.selected.reduce(
                  (tally, current) =>
                    tally && post.node.frontmatter.tags.includes(current),
                  true
                )
              )
              .map(post => (
                <BlogPostCard
                  key={post.node.frontmatter.slug}
                  title={post.node.frontmatter.title}
                  slug={post.node.frontmatter.slug}
                  description={post.node.frontmatter.description}
                  category={post.node.frontmatter.category}
                  posted_at={post.node.frontmatter.posted_at}
                  thumbnail={post.node.frontmatter.thumbnail}
                />
              ))}
          </div>
        </PostPanel>
      </PageContainer>
    )
  }
}

export default TagsPage

// export const TagsQuery = graphql`
//   query TagsQuery {
//     allMarkdownRemark(
//       filter: { fields: { sourceInstanceName: { eq: "markdown-pages" } } }
//     ) {
//       edges {
//         node {
//           frontmatter {
//             tags
//             slug
//             title
//             thumbnail
//             posted_at(formatString: "MMM YYYY")
//             category
//             description
//           }
//         }
//       }
//       distinct(field: frontmatter___tags)
//     }
//   }
// `

export const TagsQuery = graphql`
  query TagsQuery {
    allMarkdownRemark {
      edges {
        node {
          fields {
            sourceInstanceName
          }
          frontmatter {
            name
            includedtags
            tags
            slug
            title
            thumbnail
            posted_at(formatString: "MMM YYYY")
            category
            description
          }
        }
      }
      distinct(field: frontmatter___tags)
    }
  }
`
